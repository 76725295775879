import { DeviceSizeProps, styleOnTablet } from '@actr/style';
import * as AuthComponentsStyle from 'Components/account/auth/_common/style';
import styled from 'styled-components';

export const AuthPageWrapper = styled.div<DeviceSizeProps>(({ deviceSize, theme }) => ({
  paddingTop: 50,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [AuthComponentsStyle.Card]: {
    margin: '0 auto',
    maxWidth: 440,
    width: '100%',
  },
  ...styleOnTablet(deviceSize, {
    padding: `${theme.padding.x2s} ${theme.padding.s}`
  })
}));
