import { styleOnNotLastChild } from '@actr/style/src';
import { Alert, Text } from '@actr/ui';
import { FormField } from '@actr/widgets';
import * as CommonStyle from '@actr/widgets/src/components/account/auth/_common/style';
import styled from 'styled-components';

export const { Card, WrapperExtraSmall, WrapperMedium, FooterButtonsWrapper, Button } = CommonStyle;

export const FormTitle = styled(Text)(({ theme }) => ({
  display: 'block',
  marginBottom: theme.margin.x2s
}));

export const FormTitleMediumMargin = styled(Text)(({ theme }) => ({
  display: 'block',
  marginBottom: theme.margin.m
}));

export const FormHelpText = styled(Text)(({ theme }) => ({
  display: 'block',
  marginBottom: theme.margin.m,
  color: theme.colors.text.base.lighter.default
}));

export const FormFieldMediumPadding = styled(FormField)(({ theme }) => ({
  ...styleOnNotLastChild({
    paddingBottom: theme.padding.m
  })
}));

export const FormAlert = styled(Alert)(({ theme }) => ({
  ...styleOnNotLastChild({
    marginBottom: theme.margin.m
  })
}));
